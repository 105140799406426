




































import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { format } from "date-fns/esm";

@Component({})
export default class CDatePicker extends Vue {
    @Prop(Array) rules!: [];
    @Prop() label!: string;
    @Prop() value!: string;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ type: Boolean }) year!: boolean;
    @Prop({ type: String }) min!: string;
    @Prop({ type: String }) max!: string;

    private menuOpen = false;
    private x = 0;
    private y = 0;

    get datePart() {
        return this.value.substring(0, 10);
    }

    get computedDateFormatted() {
        if (!this.value) {
            return "";
        }

        // drop the time
        return format(new Date(this.value), "dd MMM yyyy");
    }
    // Prevents Vue from complaining when clear date is clicked
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set computedDateFormatted(_value) {}

    get isoValue() {
        if (!this.value) {
            return "";
        }
        // drop the time, if there
        return format(new Date(this.value), "yyyy-MM-dd");
    }

    get minYear() {
        return this.min || (new Date().getFullYear() - 100).toString();
    }

    get maxYear() {
        return this.max || (new Date().getFullYear() + 2).toString();
    }

    show(e: MouseEvent) {
        this.menuOpen = true;
        this.x = e.clientX;
        this.y = e.clientY;
    }

    @Emit("input")
    changeDate(date: Date) {
        this.menuOpen = false;
        if (!date) {
            return null;
        }

        return new Date(date).toISOString().substr(0, 10);
    }

    @Watch("menuOpen")
    onOpen(val: boolean) {
        if (!this.year) {
            return;
        }
        val && this.$nextTick(() => ((this.$refs.picker as any).activePicker = "YEAR"));
    }
}
