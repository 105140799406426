


































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
import { Registration } from "@/store/registrations-module";

import { required, email } from "@/common/validators/rules";

@Component({ components: {} })
export default class Step5 extends Vue {
    @Prop() readonly!: boolean;
    @Prop() item!: Registration;
    profileStore = getModule(ProfileModule);
    validators = { required, email };
    valid = true;

    get checkReadOnly() {
        return this.readonly && this.profileStore.profile.admin === false;
    }

    @Emit()
    prev() {
        return;
    }

    @Emit()
    save() {
        if (!(this.$refs.step5 as any).validate()) {
            return false;
        }
        return true;
    }
}
