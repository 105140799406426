




































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Registration } from "@/store/registrations-module";

import { required } from "@/common/validators/rules";

@Component({ components: {} })
export default class Step1 extends Vue {
    @Prop() readonly!: boolean;
    @Prop() value!: boolean;
    @Prop() item!: Registration;
    validators = { required };
    valid = true;

    @Emit()
    next() {
        if (!(this.$refs.step1 as any).validate()) {
            this.$emit("input", false);
            return false;
        }
        this.$emit("input", true);
        return true;
    }
}
