























































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
import { Registration } from "@/store/registrations-module";
import { Team } from "@/store/teams-api";
import { required } from "@/common/validators/rules";
import CDatePicker from "@/common/components/CDatePicker.vue";

@Component({ components: { CDatePicker } })
export default class Step2 extends Vue {
    @Prop() readonly!: boolean;
    @Prop() item!: Registration;
    @Prop() teams!: Team[];
    profileStore = getModule(ProfileModule);

    validators = { required };
    valid = true;
    dateMenu = false;

    get maxYear() {
        return (new Date().getFullYear() - 5).toString();
    }

    get checkReadOnly() {
        return this.readonly && this.profileStore.profile.admin === false;
    }

    @Emit()
    prev() {
        return;
    }

    @Emit()
    next() {
        if (!(this.$refs.step2 as any).validate()) {
            return false;
        }
        return true;
    }
}
