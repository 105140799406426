import { axios } from "./axios";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import store from "../store";

@Module({
    dynamic: true,
    namespaced: true,
    store,
    name: "registrations",
})
export class RegistrationsModule extends VuexModule {
    @Action
    getMy() {
        return axios.get<RegistrationSummary[]>("/registrations/my");
    }
    @Action
    getTeam({ teamId }: { teamId?: string }) {
        return axios.get<RegistrationSummary[]>(`/registrations/team/${teamId}`);
    }
    @Action
    getAll() {
        return axios.get<RegistrationSummary[]>("/registrations/all");
    }

    @Action
    get({ id }: { id: number }) {
        if (!id) {
            return Promise.resolve({ data: {} as Registration });
        }
        return axios.get<Registration>(`/registrations/details/${id}`);
    }

    @Action
    save({ registration }: { registration: Registration }) {
        if (!!registration.id && registration.id > -1) {
            return axios.put("/registrations", registration);
        } else {
            return axios.post("/registrations", registration);
        }
    }

    @Action
    removeRegistration({ registrationId }: { registrationId: number }) {
        return axios.delete(`/registrations/${registrationId}`);
    }
}

export interface RegistrationSummary {
    id: number;
    firstName: string;
    lastName: string;
    biPin: string;
    emergencyContact1Name: string;
    team: string;
}

export interface Registration {
    id?: number;
    agreePlayersPolicy: boolean;
    agreeParentsPolicy: boolean;
    agreePhotoMediaPolicy: boolean;
    agreeAttendancePolicy: boolean;
    agreeGdprPolicy: boolean;
    agreeMedicalPolicy: boolean;
    agreeFeesPolicy: boolean;
    agreeCovid19Policy: boolean;
    agreeGroupCommunicationsPolicy: boolean;
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    gender: number;
    address: string;
    homePhone: string;
    teamId: number;
    biPin: string;
    school: string;
    class: string;
    emergencyContact1Name: string;
    emergencyContact1Phone: string;
    emergencyContact1Email: string;
    emergencyContact2Name: string;
    emergencyContact2Phone: string;
    emergencyContact2Email: string;
    doctorName: string;
    doctorPhone: string;
    doctorAddress: string;
    medicalConditions: string;
    volunteerName: string;
    volunteerAge: number;
    volunteerContactPhone: string;
    volunteerEmail: string;
}
