
































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Registration } from "@/store/registrations-module";

import { required, email } from "@/common/validators/rules";

@Component({ components: {} })
export default class Step3 extends Vue {
    @Prop() readonly!: boolean;
    @Prop() item!: Registration;
    validators = { required, email };
    valid = true;

    @Emit()
    prev() {
        return;
    }

    @Emit()
    next() {
        if (!(this.$refs.step3 as any).validate()) {
            return false;
        }
        return true;
    }
}
